/* MAIN JS FILE
 * @version 1.0
 * @license Copyright(c) Stenik Group LTD. All rights reserved.
 * @author: gani@stenik.bg
 * -------------------------------------------------------------------
 * DEPENDENCIES:
 * --- GSAP Lib pack (mostly TweenLite, TimelineLite, Draggable)
 * --- Modernizr
 * --- dotdotdot
 * --- stenikSelect
 * -------------------------------------------------------------------
 * GLOBAL EVENTS:
 * --- 'stenik.ScrollRevealUpdate   => trigger on $(window)
 * --- 'stenik.spinnerInit'         => trigger on $(document)
 * --- 'stenik.lazyLoadReady'       => trigger on $item used in _stenikMain.lazyLoad
 * --- 'stenik.scrollTrottleUpdate' => trigger on $(window).scroll() trottle
 * -------------------------------------------------------------------
 */
import('../../scss/imports/plugins/_stenik-tooltip.scss')
import { gsap, Back, Quad } from "gsap";
const Modernizr = require("modernizr");

var $window   = $(window),
	$document = $(document);

/* Additional Modernizr tests
------------------------------------------------------------------- */
Modernizr.addTest('backgroundcliptext',function() {
	var div = document.createElement('div');
	div.style.webkitBackgroundClip = 'text';
	var text = div.style.cssText.indexOf('text');
	if (text > 0)
		return true;
	'Webkit Moz O ms Khtml'.replace(/([A-Za-z]*)/g,function(val){
		if (val+'BackgroundClip' in div.style)
			return true;
	});
});
Modernizr.addTest('pointerevents',function() {
	var a = document.createElement('x');
	a.style.cssText = 'pointer-events:auto';
	return a.style.pointerEvents === 'auto';
});

/* Small plugin for pointer-events fallback (based on MDN code)
------------------------------------------------------------------- */
$.fn.passThrough = function (target) {
	var $target = $(target);
	return this.each(function () {
		var style = this.style;
		if ('pointerEvents' in style) {
			style.pointerEvents = style.userSelect = style.touchCallout = 'none';
		} else {
			$(this).on('click mousedown mouseup mouseenter mouseleave mousemove touchstart touchend touchcancel touchmove', function (e) {
				var touch = /touch/.test(e.type) ? e.originalEvent.touches[0] || e.originalEvent.changedTouches[0] : e;
				$target.each(function() {
					var rect = this.getBoundingClientRect();
					if (e.pageX > rect.left && e.pageX < rect.right &&
						e.pageY > rect.top && e.pageY < rect.bottom)
						$(this).trigger(e.type);
				});
			});

		}
	});
};

// MAIN PUBLIC OBJECT!!!
global._stenikMain = {
	/* Some frequently used key charcodes
	--------------------------------------------------------------- */
	keys: {
		space:     32,
		enter:     13,
		backSpace: 8,
		tab:       9,
		escape:    27,
		left:      37,
		up:        38,
		right:     39,
		down:      40
	},

	/* Responsive breakpoints
	--------------------------------------------------------------- */
	breakpoints: {
		tablet:   1600,
		phablet:  950,
		phone:    650,
		handheld: 530
	},

	/* Ripple/wave click effect (Google's material design inspiration)
	--------------------------------------------------------------- */
	rippleEffect: function(items) {
		if(Modernizr.cssanimations) {
			var $item, $ripple, posY, posY;
			jQuery(document).on('mousedown', items, function(e){
				$item = jQuery(this);

				if(!$item.find('.stenikRipple').length ) { // fix multiple inits
					$item.addClass('rippleWrapper').prepend("<span class='stenikRipple'></span>");
				}
				$ripple = $item.find(".stenikRipple");

				// Stop previous animations
				$ripple.removeClass("animate");

				if( !$ripple.height() && !$ripple.width() ) {
					// Get the largest dimension(width/height) for properly circle spread
					diameter = Math.max($item.outerWidth(), $item.outerHeight());
					$ripple.css({height: diameter, width: diameter});
				}

				// Get click coordinates
				posY = e.pageY - $item.offset().top - $ripple.height()/2;
				posX = e.pageX - $item.offset().left - $ripple.width()/2;

				// Animate
				$ripple.css({ top: posY+'px', left: posX+'px' }).addClass("animate");

				// pointer events fallback
				if(!Modernizr.pointerevents && $.fn.passThrough && typeof $.fn.passThrough)
					$($ripple).passThrough($item);
			});
		}
	},

	/* Custom dotdotdot content cut
	--------------------------------------------------------------- */
	contentCut: function(contentWrapper, tolerance) {
		var $contentWrapper = jQuery(contentWrapper);
		if(!tolerance) tolerance = 0;
		$contentWrapper.each(function(index, wrapper){
			var $wrapper = jQuery(wrapper),
				$content = $wrapper.find('.content'),
				$childs  = $wrapper.children(':not(.content)');

			// Get the childs height to determine content dimensions
			var childsHeight = 0;
			for(var i = 0; i < $childs.length; i++) { // for optimization purposes
				childsHeight += jQuery($childs[i]).outerHeight(true);
			}

			// Dotdotdot cut
			$content.dotdotdot({
				wrap: 'letter',
				ellipsis: '... ',
				height: $wrapper.height() - childsHeight - tolerance
			});
		});
	},

	/* Scroll reveal animations
	--------------------------------------------------------------- */
	scrollReveal: function(selector, options){
		if(!jQuery('#ie8_check').length) {
			var $items = jQuery(selector);
			var defaults = {
				scrollTolerance: 0,
				animation:   {y: 100, rotation: '0.001deg', ease: Quad.easeOut},
				animationTime:   0.5,
				animationDelay:  0,
				staggedChild:   '',
				css3: false
			}
			var settings = $.extend({}, defaults, options);
			var windowHeight = $window.height();
			$window.resize(function() {
				windowHeight = $window.height();
			});

			// Define animations
			if(!settings.css3) {
				$items.each(function(i, item){
					var $item = jQuery(item),
						$itemToAnimate = $item;
					if(settings.staggedChild) {
						$itemToAnimate = $item.find(settings.staggedChild);
					}
					var scrollAnimation = new TimelineLite({ paused: true });
					scrollAnimation.staggerFrom($itemToAnimate, settings.animationTime, settings.animation, settings.animationDelay);

					this.scrollAnimation = scrollAnimation;
				});
			}
			// Play animation on scroll reach
			$window.scroll(function() {
				revealCall();
			});
			$window.on('stenik.scrollRevealUpdate', function() {
				revealCall();
			});
			revealCall();
		}
		var testItems;
		var scrollPosition;
		function revealCall() {
			testItems = $items.filter(':not(.scrollReached)');
			scrollPosition = $window.scrollTop() + windowHeight - settings.scrollTolerance;
			testItems.each(function(i, item) {
				if(scrollPosition >= jQuery(item).offset().top) {
					jQuery(item).addClass('scrollReached');
					if(!settings.css3) {
						this.scrollAnimation.play();
					}
				}
			});
		}
	},

	/* General auto list items width set based on wrapper's width
	--------------------------------------------------------------- */
	headerMenuItemsAlign: function(wrapper, minItems, defaultPadding) {
		var $menuWrapper = jQuery(wrapper),
			$lists = $menuWrapper.children('ul:visible'),
			$items = $lists.children('li').children('a');

		if(!minItems) minItems = $items.length;
		if(!defaultPadding) defaultPadding = 10;

		if($items.length >= minItems) {
			// --- reset
			$items.css({ paddingLeft: 0, paddingRight: 0 });
			// --- get all lists width
			var summaryWidth = 0;
			$lists.each(function(index, list) {
				summaryWidth += jQuery(list).outerWidth(true) || 0;
			});
			// --- calculate and set the new paddings
			var estimedSpace = ($menuWrapper.width() - summaryWidth)/$items.length - 0.1 // '0.1' - font rendering fix;
			if(estimedSpace > 0) {
				$items.css({
					paddingLeft: estimedSpace/2,
					paddingRight: estimedSpace/2
				});
			}
		} else {
			// --- reset to default
			$items.css({ paddingLeft: defaultPadding, paddingRight: defaultPadding });
		}
	},

	/* Custom checkboxes and radio buttons
	--------------------------------------------------------------- */
	checkboxAndRadioBeautify: function(formItems) {
		jQuery(formItems).each(function(index, formItem){
			var $formItem = jQuery(formItem),
				$input    = $formItem.find('input'),
				inputType = $input.attr('type'),
				inputID   = $input.attr('id'),
				inputName = $input.attr('name');

			// Markup generate
			$formItem.wrapInner('<div class="stenik-'+inputType+'"></div>');
			$input.wrap('<div class="inputWrapper"></div>');
			var $wrapper = $formItem.find('.stenik-'+inputType);

			// If the input doesn't have associated label, create it
			if($formItem.find('label[for="'+inputID+'"]').length == 0) {
				$formItem.append('<label for="'+inputID+'"></label>')
			}
			var $label = $formItem.find('label').first();
			$label.wrapInner('<div class="labelContent"></div>'); // helper wrapper

			$input.on('focus', function() {
				$wrapper.addClass('focus');
			});
			$input.on('focusout', function() {
				$wrapper.removeClass('focus');
			});

			// Check input default state
			if( $input.is(':checked') ) {
				$wrapper.addClass('selected');
			}

			// Checkbox
			if(inputType == 'checkbox') {
				$input.on('change', function() {
					if($input.is(':checked')) {
						// --- check
						$input.attr('checked', 'checked');
						$wrapper.addClass('selected');
					} else {
						// --- uncheck
						$input.removeAttr('checked');
						$wrapper.removeClass('selected');
					}
				});
			}

			// Radio buttons
			else if(inputType == 'radio') {
				jQuery('[name="'+inputName+'"]').on('change', function() {
					// --- reset
					$wrapper.removeClass('selected');

					// --- set
					var $this = jQuery(this);
					$this.attr('checked', 'checked')
						 .parents('.stenik-'+inputType).first().addClass('selected');
				});
			}
		});
	},

	/* Content toggle
	--------------------------------------------------------------- */
	contentToggle: function(selector, callback){
		jQuery(selector).each(function(i, wrapper){
			var $wrapper = jQuery(wrapper),
				$handle  = $wrapper.find('.toggleHandle'),
				$content = $wrapper.find('.toggleContent');
			var wrapperMinHeight;
			$handle.on('click', function() {
				if($wrapper.hasClass('selected')) {
					// hide
					wrapperMinHeight = $handle.outerHeight(true);
					$wrapper.removeClass('selected').css({ minHeight: wrapperMinHeight });
				} else {
					// show
					wrapperMinHeight = $handle.outerHeight(true) + $content.outerHeight(true);
					$wrapper.addClass('selected').css({ minHeight: wrapperMinHeight });
				}

				if(callback && typeof callback === 'function') { callback(); }
			});
		});
	},

	/* General lazy load images
	--------------------------------------------------------------- */
	lazyLoad: function(_items, numberOfFirstLoadedImages) {
		var $items = jQuery(_items),
			totalItems = $items.length;

		var imagesLoaded = 0;
		if(totalItems) {
			// // Array boundaries check
			// if(!numberOfFirstLoadedImages) {
			// 	numberOfFirstLoadedImages = 0; // will load all images on window.load
			// } else if(numberOfFirstLoadedImages > totalItems) {
			// 	numberOfFirstLoadedImages = totalItems;
			// }
			// console.log(numberOfFirstLoadedImages)
			// if(numberOfFirstLoadedImages === 0)
			// {
			// 	numberOfFirstLoadedImages = 10;
			// }
			// // Load the first images on document.ready
			// iteratorCall(0, numberOfFirstLoadedImages);

			// // Load the other images on window.load
			// window.addEventListener('DOMContentLoaded', (event) => {
			// 	iteratorCall(numberOfFirstLoadedImages, totalItems);
			// });
			
			//Run the entire load because firefox not always runs load event...
			iteratorCall(numberOfFirstLoadedImages, totalItems);
		}
		function iteratorCall(start, end){

			if(start != 'undefined' && end != 'undefined') {
				for(var i = start; i < end; i++) {
					var $wrapper  = jQuery($items[i]);
					loadImg($wrapper);
				}
			}
			console.log(imagesLoaded, totalItems, start, end)
			if(imagesLoaded == totalItems) {
				// Trigger ready state
				$items.trigger('stenik.lazyLoadReady');
			}
		}
		function loadImg($_wrapper) {
			if($_wrapper) {
				var imgUrl    = $_wrapper.data('img-url'),
					imgAlt    = $_wrapper.data('img-alt') || '',
					imgWidth  = $_wrapper.data('img-width') || 'auto',
					imgHeight = $_wrapper.data('img-height') || 'auto',
					imgClass  = $_wrapper.data('img-class') || '';

				if(imgUrl) {
					var $img = jQuery('<img src="'+ imgUrl +'" alt="'+ imgAlt +'" width="'+ imgWidth +'" height="'+ imgHeight +'" class="'+ imgClass +'" />');
					$img.on('load', function() {
						//console.log('Image successfully loaded!');
						imagesLoaded++;
						$_wrapper.prepend($img);
						$_wrapper.addClass('imgLoaded');
					});
				}
			}
		}
	},


	/* On document ready fade in some hidden elements
	--------------------------------------------------------------- */
	fadeInPageElements: function(selector){
		jQuery('.twoSidesBar.cinemaSelect .checkList').delay(200).fadeIn();
		jQuery('.scheduleRow .rowHeader .meta').delay(200).fadeIn();
		jQuery('.movieBox .buttonSet .row').delay(400).css('opacity', 1);
		jQuery('.pageDesc.profile').css('opacity', 1);
	},



	/* Fallback horizontal absolute positioned elem align
	--------------------------------------------------------------- */
	absoluteHCenter: function(selector){
		if(jQuery('#ie9_check').length || jQuery('#ie8_check').length) {
			jQuery(selector).each(function(i, item){
				jQuery(item).css({
					marginLeft: -jQuery(item).width()/2
				});
			});
		}
	},

	/* Simple number input spinner
	--------------------------------------------------------------- */
	spinner: function(input_selector){
		var $inputs = jQuery(input_selector);
		$inputs.each(function(i, input){
			var $input   = jQuery(input),
				minValue = parseInt($input.data('min')),
				maxValue = parseInt($input.data('max'));

			if(isNaN(minValue)) minValue = -999;
			if(isNaN(maxValue)) maxValue = 999;

			if(!$input.val())
				$input.val(minValue);

			$input.attr('disabled', 'disabled');

			// Set spinner's status (use it for dynamic switch on/off)
			$input.data('stenik.spinnerEnable', 1);

			// Markup generate
			if(!$input.parents('.stenikSpinner').first().length) {
				$input.wrap('<div class="stenikSpinner"></div>');

				var $wrapper = $input.parents('.stenikSpinner').first();
				$wrapper.prepend('<span class="spinnerHandle minus">-</span>');
				$wrapper.append('<span class="spinnerHandle plus">+</span>');
				var $minus = $wrapper.find('.minus');
				var $plus = $wrapper.find('.plus');

				// Actions
				$minus.on('click', function(e){
					e.preventDefault();
					$input.trigger('stenik.spinnerBeforeChange', [{action: 'substract'}]); // get the status before any math comparision
					console.log($input, [{action: 'substract'}])
					if($input.data('stenik.spinnerEnable') == 1) {
						var inputValue = (isNaN($input.val()))? 0 : parseInt($input.val());
						if(inputValue > minValue) {
							$input.val(inputValue-1)
								.trigger('change')
								.trigger('stenik.spinnerChange'); // manual trigger input change
						}
					}
				});
				$plus.on('click', function(e){
					e.preventDefault();
					$input.trigger('stenik.spinnerBeforeChange', [{action: 'add'}]); // get the status before any math comparision

					if($input.data('stenik.spinnerEnable') == 1) {
						var inputValue = (isNaN($input.val()))? 0 : parseInt($input.val());
						if(inputValue < maxValue) {
							$input.val(inputValue+1)
								.trigger('change')
								.trigger('stenik.spinnerChange'); // manual trigger input change
						}
					}
				});
			}

			

			// Tirgger global init event
			$document.trigger('stenik.spinnerInit');
		});
	},

	/* General Cookies access/create/delete methods
	--------------------------------------------------------------- */
	isValidDate: function(testDate) { // return true || false
		// An invalid date object returns NaN for getTime()
		// and NaN is the only object not strictly equal to itself.
		// >>> see benchmark test on http://jsperf.com/detecting-an-invalid-date
		var testDateTime = testDate.getTime();
		return testDateTime === testDateTime;
	},
	setCookie: function(name, value, date) {
		if(name) {
			var expiresDate = new Date(date),
				expires = '';
			if (_stenikMain.isValidDate(expiresDate)) {
				var expires = "; expires="+expiresDate.toGMTString();
			}
			document.cookie = name + '=' + value + expires + '; path=/';
		}
	},
	getCookie: function(name) {
		var nameEQ = name + "=";
		var cookies = document.cookie.split(';');
		for(var i=0; i < cookies.length; i++) {
			var cookie = cookies[i];
			while (cookie.charAt(0) == ' ')
				cookie = cookie.substring(1, cookie.length);
			if (cookie.indexOf(nameEQ) == 0)
				return cookie.substring(nameEQ.length, cookie.length);
		}
		return null;
	},
	deleteCookie: function(name) {
		_stenikMain.setCookie(name,"",-1);
	},

	/* Enable/disable form submit event if all required checkboxes/radios are checked
	--------------------------------------------------------------- */
	formCheck: function(form) {
		return jQuery(form).each(function() {
			var $form = jQuery(this);
			if($form.data('init.formCheck') !== true) {
				$form.data('init.formCheck', true);
				// @TODO fix IE missing required attr error
				var $checks = $form.find('.formSubmitToggle');
				// if(Modernizr.input.required) {
				// 	$checks = $checks.add('input[type="checkbox"]:required');
				// }
				if($checks.length) {
					var checkedItems = 0;
					$checks.on('change', function() {
						formStatus($form, $checks, checkedItems);
					});
					formStatus($form, $checks, checkedItems);
					$form.on('submit', function(e) {
						if(!formStatus($form, $checks, checkedItems)) {
							e.preventDefault();
							e.stopPropagation();
						}
					});
				}
			}
		});
		function formStatus($form, $checks, checkedItems) {
			$checks.each(function() {
				if($(this).is(":checked")) {
					checkedItems++;
				} else {
					checkedItems--;
				}
			});

			if(checkedItems === $checks.length) {
				$form.find('input[type="submit"], button, .button').removeClass('disabled');
				return true;
			} else {
				$form.find('input[type="submit"], button, .button').addClass('disabled');
				return false;
			}
		}
	},

	/* Detect mobile devices
	--------------------------------------------------------------- */
	isMobile: function() {
		var userAgentSting = navigator.userAgent;
		function handheldTouch() {
			try {
				document.createEvent('TouchEvent');
				return true;
			}
			catch(e) {
				return false;
			}
		}

		// Windows Phone 7 detect
		if( userAgentSting.toLowerCase().search('windows phone os 7') > -1 ) {
			jQuery('body').addClass('ieMobile7');
		}
		if( userAgentSting.match(/Android/i)
		 || userAgentSting.match(/Mobile/i)
		 || userAgentSting.match(/Tablet/i)
		 || userAgentSting.match(/webOS/i)
		 || userAgentSting.match(/Opera Mini/i)
		 || userAgentSting.match(/Opera Mobi/i)
		 || userAgentSting.match(/iPhone/i)
		 || userAgentSting.match(/iPad/i)
		 || userAgentSting.match(/iPod/i)
		 || userAgentSting.match(/BlackBerry/i)
		 || userAgentSting.match(/Windows Phone/i)
		 || userAgentSting.match(/IEMobile/i)
		 || userAgentSting.match(/BB & Mobile Safari/i)
		 || userAgentSting.match(/BlackBerry & Mobile Safari/i)
		 || /FxiOS/.test(navigator.userAgent) && userAgentSting.match(/Firefox/i) != null
		)
		{
			return true;
		}
		return false;
	},

	/* IE check
	--------------------------------------------------------------- */
	isIE: function() {
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf("MSIE ");
		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
			return true;
		} else {
			return false;
		}
	},

	/* Hover helpers
	--------------------------------------------------------------- */
	hoverClass: function(selector, setOnParent) { // setOnParent coulc be 'true' or parent's selector
		if($(selector).length) {
			$(selector).each(function() {
				var $target = $(this);
				if(setOnParent) {
					if(setOnParent === true) {
						$target = $(this).parent();
					} else {
						$target = $(this).parents(setOnParent).eq(0);
					}
				}
				$(this).on('mouseenter', function() {
					$target.addClass('hover');
				}).on('mouseleave', function() {
					$target.removeClass('hover');
				});
			});
		}
	},

	/* Class toggle
	--------------------------------------------------------------- */
	classToggle: function(elem, target, className, outsideClick) {
		var $target = jQuery(target);
		var $elem   = jQuery(elem);
		$elem.click(function(e) {
			e.preventDefault();
			if($target.hasClass(className)) {
				$target.removeClass(className);
			} else {
				$target.addClass(className);
			}
		});
		if(outsideClick == true) {
			$(document).on('click', function(e) {
				if( !$elem.is(e.target) && !jQuery(e.target).has($elem).length == 0 ) {
					if($target.hasClass(className))
						$target.removeClass(className);
				}
			});
		}
	}
};


jQuery(function($){
	console.log(_stenikMain)
	/* Cached variables
	--------------------------------------------------------------- */
	var	$html          = $('html'),
		$body          = $('body'),
		$header        = $('#header'),
		$animatedBg    = $('#animated_bg'),
		$topSlider     = $('#top_slider'),
		$scrollToTop   = $('#scroll_to_top'),
		$globalWrapper = $('#global_wrapper'),
		$movieBox      = $('.movieBox'),
		isIE8          = $('#ie8_check').length,
		isIE9          = $('#ie9_check').length,
		isMobile       = _stenikMain.isMobile();

	var animationsFallback = _stenikMain.isIE();
	// Force page show
	setTimeout(function() {
		if(!$body.hasClass('loaded')) {
			$body.addClass('loaded');
			$body.addClass('afterLoaded');
		}
		if($body.hasClass('loaded') && !$body.hasClass('afterLoaded')) {
			$body.addClass('afterLoaded');
		}
	}, 4000);

	// Movies Highlight filter cookie
	var moviesTabCookie = _stenikMain.getCookie('moviesLayout');
	if(moviesTabCookie == 'small') {
		$('.moviesHighlight').find(' .filterContent').addClass('small');
	} else {
		$('.moviesHighlight').find(' .filterContent').removeClass('small');
	}

	/* Style helpers
	--------------------------------------------------------------- */
	if(isIE8) {
		$body.addClass('ie8');
	}
	if(isIE9) {
		$body.addClass('ie9');
	}
	if(animationsFallback) {
		$body.addClass('animationsFallback');
	}
	if($('.brandingWrapper').length) {
		$body.addClass('hasBranding')
	}

	// HTML5 placeholder polyfill (fallback!)
	$('input, textarea').placeholder();
	if(isIE8) {
		$document.on('cbox_complete', function() {
			$('input, textarea').placeholder();
		});
	}

	// Target first and last table cell (fallback!)
	$('table tr:first-child, table td:first-child, table th:first-child').addClass('first');
	$('table tr:last-child, table td:last-child, table th:last-child').addClass('last');

	// Top slider layout helper
	if($topSlider.length) {
		$body.addClass('hasTopSlider');
	}
	// Generate buttons markup for easier none-dev html input
	$('.button').wrapInner('<span class="txt"></span>')
				.append('<span class="buttonBg"></span>');

	// Header menu items content detection (for custom hover effects)
	$header.find('.item > ul > li > a').each(function() {
		var $this = jQuery(this);
		if($this.find('img').length ||  $this.find('i').length) {
			$this.parent().addClass('imgItem');
		} else {
			$this.parent().addClass('txtItem');
		}
	});

	// Datepicker focus class toggle
	$('.datepicker input')
		.focus(function() {
			$(this).parent().addClass('focused');
		})
		.focusout(function() {
			$(this).parent().removeClass('focused');
		});

	// Helper hover classes
	_stenikMain.hoverClass('.timelineSet .item:not(.disabled)');
	_stenikMain.hoverClass('.cinemaBox');
	_stenikMain.hoverClass('.linkGroup a', true);

	// Tooltips
	var $tooltipsHolder = jQuery('<div class="stenikTooltips"></div>');
	var tooltipsCount = 0;
	$body.after($tooltipsHolder);
	$('[data-tooltip]').each(function(i, tooltipTarget) {
		var $tooltipTarget = $(tooltipTarget);
		var customClass = $tooltipTarget.data('tooltip-class');
		if(!customClass) customClass = '';

		var $tooltip = jQuery( '<div id="stenik_tooltip_' + i + '" class="tooltip ' + customClass + '">' +
									'<div class="vAlign">' + $tooltipTarget.data('tooltip') + '</div>' +
								'</div>');

		$tooltipTarget.addClass('tooltip_'+i);
		$tooltipsHolder.append($tooltip);

		$tooltipTarget.hover(function(e){
			$tooltip.addClass('active').css({
				left: $(this).offset().left + $(this).outerWidth()/2,
				top: $(this).offset().top - $tooltip.height() - 20
			});
		}, function(e) {
			$tooltip.removeClass('active');
		});
	});

	// Step's triangles generator
	var $triangleWrappers = jQuery('[data-triangles]');
	$triangleWrappers.each(function(index, triangleWrapper){
		var $triangleWrapper = jQuery(triangleWrapper);
		var trianglesCount = parseInt($triangleWrapper.data('triangles')) || 0;
		for(var i = 1; i <= trianglesCount; i++) {
			$triangleWrapper.append('<span class="triangle-set item-'+i+'">');
		}
	});

	// Simple form button toggle
	_stenikMain.formCheck('form');
	$document.on('cbox_complete', function() {
		_stenikMain.formCheck('form');
	});

	// Prevent fireing click event on disabled items click
	$document.on('click', 'button.disabled, .timelineSet .disable', function(e) {
		e.preventDefault();
		e.stopPropagation();
	});

	// Scroll to top
	var scrollPosition = 0;
	$scrollToTop.click(function(e){
		$('html, body').animate({
			scrollTop: 0
		}, 600);
	});

	var  scrollTimer = null;
	if(!isMobile) {
		$window.scroll(function() {
			scrollPosition = $window.scrollTop();
			if(!animationsFallback) {
				if(scrollPosition > 20) {
					if(!$body.hasClass('scrolling'))
						$body.addClass('scrolling');
				} else {
					$body.removeClass('scrolling');
				}
			}
			// ScrollToTop btn show toggle
			if(scrollPosition > 400) {
				$body.addClass('showScrollToTop');
			} else {
				$body.removeClass('showScrollToTop');
			}
		});
		// disable pointer events while scrolling
		clearTimeout(scrollTimer);
		if(!$body.hasClass('disable-hover')) {
			$body.addClass('disable-hover');
		}
		scrollTimer = setTimeout(function(){
			if($body.hasClass('disable-hover')) {
				$body.removeClass('disable-hover');
			}
		}, 250);
	}


	/* Movie box hover animation
	--------------------------------------------------------------- */
	$movieBox.each(function(i,box) {
		var movieBoxTimeline = gsap.timeline({ paused: true }),
			$box = $(box),
			$buttons = $box.find('.hoverContent .buttonSet .row'),
			$timeline = $box.find('.hoverContent .timelineSet');

		movieBoxTimeline.from($buttons, 0.6, { bottom: "101%", ease:Back.easeOut }, 0.1, 0.2)
						.to($timeline, { duration: 0.7, bottom: 0, ease:Back.easeOut }, 0.2);
		console.log(movieBoxTimeline)
		this.movieBoxTimeline = movieBoxTimeline;
	});
	$document.on('mouseenter', '.movieBox', function() {
		this.movieBoxTimeline.play();
	}).on('mouseleave', '.movieBox', function() {
		this.movieBoxTimeline.reverse();
	});


	/* Some function & plugin calls
	--------------------------------------------------------------- */
	// Beautify forms (checkboxes and radio buttons)
	_stenikMain.checkboxAndRadioBeautify('.formItem.radio');
	_stenikMain.checkboxAndRadioBeautify('.formItem.checkbox');
	$('.formItem').find('select').stenikSelect();
	$document.on('cbox_complete', function() {
		// trigger again for colorbox async load
		_stenikMain.checkboxAndRadioBeautify('.formItem.radio');
		_stenikMain.checkboxAndRadioBeautify('.formItem.checkbox');
		$('.formItem').find('select').stenikSelect();
		$.fn.colorbox.resize();
	});

	// Stenik quantity spinner inputs
	if(!isMobile) {
		$document.on('stenik.spinnerInit', function() { // note: must be called before init
			// --- attach the buttons ripple efect on init
			_stenikMain.rippleEffect('.spinnerHandle');
		});
	}
	_stenikMain.spinner('.quantitySpinner');

	// Fallback for 'pointer-events: none;'
	$('.mapWrapper .bgLayer').passThrough('#map_canvas');

	// Lazyload
	_stenikMain.lazyLoad('.movieBox .lazyLoad', 0);

	// Scroll reveal animations
	//_stenikMain.scrollReveal('.banners.type1', {
	//	scrollTolerance: 100,
    //	css3: true
		// animation: {
		// 	y: 100,
		// 	opacity: 0,
		// 	ease: Power1.easeOut
		// },
		// animationTime: 0.7,
		// animationDelay: 0.3,
		// staggedChild: '.banner'
	//});
	//_stenikMain.scrollReveal('.postsColWrap ~ .banners.type2', {
		//scrollTolerance: 120,
		//css3: true
	//});
	//_stenikMain.scrollReveal('.postsColWrap.left, .postsColWrap.right', {
		//scrollTolerance: 200,
		//css3: true
	//});
	_stenikMain.scrollReveal('#footer .top, #footer .infoTooltips, #footer .newsLetter .stdForm', {
		scrollTolerance: 70,
		css3: true
	});
	_stenikMain.scrollReveal('#footer .bottom', {
		scrollTolerance: 0,
		css3: true
	});

	// Firefox focus drag bug fix
	$('.timelineSet .item').on('dragstart', function(e) {
		e.preventDefault();
		e.stopPropagation();
	});

	// Slow server response loader
	var linkTimer = null;
	if($('.pageLinkLoader').hasClass('active')) {
		$('.pageLinkLoader').removeClass('active');
	}
	$window.on('beforeunload', function() {
		clearTimeout(linkTimer);
		linkTimer = setTimeout(function() {
			$('.pageLinkLoader').removeClass('active');
		}, 700);
	});
	setTimeout(function() {
		$('.pageLinkLoader').removeClass('active');
	}, 700);
	$document.ajaxStart(function() {
		clearTimeout(linkTimer);
		linkTimer = setTimeout(function() {
			$('.pageLinkLoader').addClass('active');
		}, 700);
	}).ajaxStop(function() {
		clearTimeout(linkTimer);
		$('.pageLinkLoader').removeClass('active');
	}).ajaxError(function() {
		$('.pageLinkLoader').removeClass('active');
	}).ajaxComplete(function() {
		$('.pageLinkLoader').removeClass('active');
	});

	// Auto open colorbox for image/video links
	$('a').each(function(i, el) {
		var href_value = el.href;
		if (/\.(jpg|jpeg|png|gif|bmp|tif|tiff|JPG|JPEG)$/.test(href_value)) { // is pic
			$(el).colorbox({
				fixed:     true,
				maxWidth:  '90%',
				maxHeight: '90%',
				html: function(){
					var title = $(this).attr('title') || '';
					return '<div class="popup autoWidth"><img src="'+href_value+'" alt="'+title+'"></popup>';
				}
			});
		}
		// else if (/\b(?:vimeo|youtube|dailymotion)\.com\b/i.test(href_value)) { // is video
		// 	$(el).colorbox({
		// 		iframe:           true,
		// 		width:            900,
		// 		height:           600,
		// 		maxWidth:         '90%',
		// 		maxHeight:        '90%',
		// 		className:        'videoPopup',
		// 		top:              true,
		// 		transition:       null,
		// 		stenikAnimations: true
		// 	});
		// }
	});
	$('.closePopup').click(function() {
		 $.fn.colorbox.close();
	});
	$window.resize(function() {
		$.fn.colorbox.resize();
	});

	


	/* WINDOW.LOAD
	--------------------------------------------------------------- */
	$window.on('load', function() {
		// Auto align header items
		if(!isMobile) {
			// _stenikMain.headerMenuItemsAlign('#header .item.left', 3, 10);
			// _stenikMain.headerMenuItemsAlign('#header .item.right', 3, 10);
			$body.addClass('menuLoaded');
		}

		_stenikMain.contentToggle('.contentToggle');

		_stenikMain.fadeInPageElements();

		// Universal tab slider menu
		$('.stenikTabsHeader').each(function() {
			var $headerItems  = $(this).find('.tabItem');
			var headerWidth   = $(this).width();
			var rows          = $(this).data('rows');

			// --- rows generator (words wrapping)
			if(rows) {
				$headerItems.each(function(i, item) {
					var $item = $(item);
					var textContent = $.trim($item.text());
					if(textContent.length) { // if has text
						var words = textContent.split(' '),
						length    = words.length;

						var resultRows     = [],
							cutStart       = 0,
							cutEnd         = 0,
							remainingWords = length,
							remainingRows  = rows;

						for(var i = 0; i < rows; i++) {
							if(remainingWords > 0) {
								var row;
								var countWordsToJoin = 1;

								// --- merge more than one word in one row (design specification)
								if(remainingWords > remainingRows) {
									countWordsToJoin = Math.ceil(remainingWords/remainingRows);
								}

								cutStart = cutEnd;
								cutEnd += countWordsToJoin;

								row = words.slice(cutStart, cutEnd);
								resultRows[i] = '<div class="row">' + row.join(' ') + '</div>';

								remainingWords -= countWordsToJoin;
								remainingRows--;
							}
						}
						$item.html(resultRows);
					}
				});
			}

			var totalWidth = 0,
				maxWidth   = 0,
				selectedIndex = 0;
			$headerItems.each(function(i, item) {
				var itemWidth = $(item).innerWidth();
				totalWidth += itemWidth;
				if(itemWidth > maxWidth) {
					maxWidth = itemWidth; // get the biggest item's width
				}
				if($(item).hasClass('selected'))
					selectedIndex = $(item).index();
			});

			// --- calculate items width
			var itemsPerSlide = $headerItems.length;
			var maxItems = Math.floor(headerWidth / maxWidth);
			if(itemsPerSlide > maxItems) {
				itemsPerSlide = maxItems;
			}
			var tabsSliderOptions = {
				items: itemsPerSlide,
				loop: false,
				nav: true,
				dots: false,
				navText: '',
				autoplay: false,
				mouseDrag: ($headerItems.length <= itemsPerSlide)? false : true
			};
			var $tabSlider = $(this).find('.sliderWrapper');
			$tabSlider.owlCarousel(tabsSliderOptions)
				.trigger('to.owl.carousel', [selectedIndex, 5, true])
				.on('changed.owl.carousel', function(event) {
					if(_stenikMain.isMobile())
					{
						if($($(event.currentTarget).find('.owl-item')[event.item.index]).find('.filter').length)
						{
							$($(event.currentTarget).find('.owl-item')[event.item.index]).find('.filter').trigger('click')
						}
					}
				});
		});

		// Stenik ipple efect init
		if(!isMobile) {
			_stenikMain.rippleEffect('.button:not(.searchButton), .stenikSelect .option, ' +
									 '.scheduleRow .timelineSet .time, .interests label .labelContent, .removeItem');
		}

		// Dotdotdot cuts
		$movieBox.find('title').dotdotdot();
		_stenikMain.contentCut('.post:not(.inline) .contentWrapper');

		// Helper load classes
		// $body.addClass('loaded');

		setTimeout(function() {
			$body.addClass('afterLoaded');
			$window.trigger('stenik.pageAfterLoaded');
		}, 850);

	});

	/* RESPONSIVE STUFFS
	--------------------------------------------------------------- */
	if(isMobile) {
		$body.addClass('mobileView');
		$('.filterContent').removeClass('small');
		if(!$('#filter_bar').length) {
			$('#responsive_filter_handle').hide();
		}
		window.addEventListener('orientationchange', function() {
			$('.post:not(.inline) .contentWrapper').trigger('update');
		});

		_stenikMain.classToggle('#responsive_menu_handle', $body, 'responsiveMenuActive', true);
		_stenikMain.classToggle('#responsive_filter_handle', $body, 'responsiveFilterActive', true);
		_stenikMain.classToggle('#global_responsive_menu_toggle', $body, 'responsiveFilterActive', true);
		$('#coming_soon_menu_link').click(function() {
			$body.removeClass('responsiveMenuActive');
		});
		if($window.width() <= _stenikMain.breakpoints.phablet) {
			$('.cinemaSidebar').insertAfter('.cinemaContent');
		}
		$('.sidebar:not(.cinemaSidebar) ul').tinyNav();

		$(document).on('click', '[data-responsive-href]', function() {
			var href = $(this).data('responsive-href');
			if(href)
				window.location.href = href;
		});

		setTimeout(function() {
			$(".mobileView #header .logo img").show();
		}, 2000);
		$(".mobileView .movie--details").appendTo("#mobileDescContent");
		$(".mobileView .block.projectionDays .sameCatMovieResponsiveWrapper").delay(150).appendTo("#main .wrapper:first");
		$(".mobileView .shareBox").delay(200).appendTo("#main .wrapper:first");
		$(".bookingWrapper .info").appendTo(".bookingWrapper:first");
		$(".bookingWrapper .bookingTotal .note").delay(10).appendTo(".bookingTotal:first");
		$(".bookingWrapper .stdForm .moviePlot").delay(12).appendTo(".bookingWrapper .stdForm:first");
		$(".bookingWrapper .stdForm .contentWrap").delay(15).prependTo(".bookingWrapper .stdForm:first");
		$(".bookingWrapper.completeOrder .bookingTotal").prependTo(".bookingWrapper:first");


	}
});

global._stenikMain = _stenikMain;
export default _stenikMain;